<template>
  <esmp-modal
    v-model="isShowed"
    width="400"
    title="Добавление подсказки"
  >
    <esmp-input
      label="Подсказка"
      :options="{ type: 'textarea' }"
      v-model="info"
    />
    <template #footer>
      <esmp-button @click="closeHandler" view="outline">
        Отмена
      </esmp-button>
      <esmp-button @click="submitHandler">
        Применить
      </esmp-button>
    </template>
  </esmp-modal>
</template>

<script>
export default {
  name: 'ModalCmdbKeAddComment',
  model: {
    prop: 'showed',
    event: 'input',
  },
  props: {
    showed: Boolean,
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      info: '',
    };
  },
  computed: {
    isShowed: {
      get() {
        return this.showed;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    submitHandler() {
      this.$emit('set', this.info);
      this.closeHandler();
    },
    closeHandler() {
      this.$emit('input', false);
    },
  },
  watch: {
    showed(value) {
      this.info = value ? this.item.info || '' : '';
    },
  },
};
</script>
